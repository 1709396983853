import React from 'react'
import {Link} from 'gatsby'
import Hidden from  '@material-ui/core/Hidden'

export default class Breadcrumb extends React.Component{

    render(){
        const { path, title } = this.props
        let array = path.split('/').filter((e) => {
            return e !== ''
        })

        return(
            <Hidden smDown>
                <div className="breadcrumbs-wrapper">
                    <div className="breadcrumbs">
                        <Link to="/">Hem</Link>
                        {array && array.map((link, i) => (
                        <Link to={`/${link}`} key={i}>{link}</Link>
                        ))}
                    </div>
                    <div className="page-title">
                        {title && title !== '' ? <h2>{title}</h2> : ''}
                    </div>
                </div>
            </Hidden>
        )
    }
}